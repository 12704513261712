/* #region monc allgemein */
@import url("https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Ubuntu Condensed", sans-serif;
}

main {
  font-family: "Ubuntu Condensed", sans-serif;
  font-size: 130%;
  margin-left: 3rem;
  margin-right: 3rem;
  /* stylelint-disable */
  max-width: 1500px;
  /* stylelint-enable */
}

.monc_start {
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  padding: 0;
}

.monc_side_img {
  max-width: 20%;
  padding: 0;
}

.monc_btn {
  /* stylelint-disable */
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  /* stylelint-enable */
  font-size: 110%;
  height: 2.5em;
}

.monc_btn_no {
  background-color: #FFF;
  border: 0.0625rem solid rgba(0, 0, 0, 0.125);
}

.monc_btn_small {
  min-width: 8em;
}

.monc_btn_YN {
  display: inline-block;
  min-width: 5em;
}

.monc_btn_middel {
  height: 2.5em;
  min-width: 11em;
}

.monc_h {
  font-family: "Ubuntu", sans-serif;
  font-size: 200%;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 0.8em;
  margin-top: 0;
  text-transform: unset;
}

.monc_msg,
.monc_msg p {
  display: inline-block;
  font-family: "Ubuntu", sans-serif;
  font-size: 110%;
  font-style: normal;
  font-weight: 400;
  margin-right: 1em;
}

.monc_content {
  font-size: 110%;
  line-height: normal;
  margin-left: 3rem;
  padding: 0;
}

.monc_img_placeholder {
  background-color: transparent;
  height: 8em;
}

.monc_img_acc {
  height: 8em;
  margin-left: 2em;
  padding: 1em;
  width: auto;
}

.monc_img_loading {
  margin-left: 40%;
  margin-top: 5em;
  width: 15%;
}

.monc-card {
  background-color: #EEE;
  /* stylelint-disable */
  border: 2px solid #EEE;
  border-radius: 6px;
  width: 300px;
  /* stylelint-enable */
}

.intro_next,
.support_btn {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
}

.intro_next {
  margin-right: 1em;
}

.support_btn label,
.share_btn label {
  margin-right: 1em;
}

.monc_footer {
  align-items: flex-end;
  background-color: #EEE;
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
  padding-bottom: 1em;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1em;
}

.speaker_next,
.micro_next,
.video_next {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.monc_next_dashboard {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

#audioOutput,
#audioInput,
#videoSource {
  margin-left: 1em;
}

/* overview */
.monc-card-nocolor {
  background-color: transparent;
  /* stylelint-disable */
  border: 2px solid #EEE;
  box-shadow: 7px 7px #EEE;
  /* stylelint-enable */
  font-size: 150%;
  margin-right: 1em;
  margin-top: 1em;
  max-width: 15.5em;
  width: 15.5em;
}

.monc-card-nocolor p {
  display: inline;
  padding: 0;
  width: 90%;
}

.monc_img_small {
  height: 5em;
  margin: 0;
  padding: 0;
  width: auto;
}

.monc_img_small_placeholder {
  height: 5em;
  width: 8em;
}

.overview_side {
  align-items: baseline;
  display: flex;
  justify-content: flex-start;
  /* stylelint-disable */
  max-width: 350px;
  /* stylelint-enable */
  width: 25%;
}

/* summary */
.monc_sum_txt {
  font-size: 120%;
  margin-left: 0.5em;
}

.monc_sum_state {
  border-radius: 0.625rem;
  font-size: 130%;
  margin-right: 0.5em;
}

.monc_input {
  background-color: #EEE;
  /* stylelint-disable */
  border-radius: 4px;
  /* stylelint-enable */
  border-style: solid;
  padding: 0.625rem;
}

#email {
  width: 60%;
}

.monc_msg_sum,
.monc_msg_sum p {
  display: inline-block;
  font-family: "LiberationSansRegular", sans-serif;
  font-size: 110%;
  font-style: normal;
  font-weight: 800;
  margin: 0.25em 0 0;
}

.share_btn {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.radio_share_sm {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.kb_index {
  align-items: baseline;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style-type: none;
  padding-left: 0;
}

.kb_index li:hover {
  color: #EE7F01;
}

.kb_index li {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}

#kb_content {
  /* stylelint-disable */
  border: 2px solid #EEE;
  /* stylelint-enable */
  height: 40em;
  width: 100%;
}

/* #region Radio btn */
.radio-item {
  display: inline-block;
  margin-top: 1em;
  padding: 0 0.6em;
  position: relative;
}

.radio-item input[type="radio"] {
  display: none;
}

.radio-item label {
  color: #666;
  font-family: "Ubuntu Condensed", sans-serif;
  font-size: 130%;
  font-weight: 800;
}

.radio-item label::before {
  background-color: transparent;
  /* stylelint-disable */
  border: 2px solid #000;
  border-radius: 50px;
  /* stylelint-enable */
  content: " ";
  display: inline-block;
  height: 1.5em;
  margin: 0 0.5em 0 0;
  position: relative;
  top: 0.5em;
  width: 1.5em;
}

.radio-item input[type="radio"]:checked + label::after {
  background: #EE7F01;
  /* stylelint-disable */
  border: 4px solid #000;
  border-radius: 50px;
  /* stylelint-enable */
  content: " ";
  display: block;
  height: 1.52em;
  left: 0.5em;
  position: absolute;
  top: 0.5em;
  width: 1.52em;
}

/* #endregion radiobtn */

/* #endregion monc allgemein */

/* #region videotest */
#video {
  margin: 1em 4em 4em;
  max-height: 10em;
  object-fit: scale-down;
}

.video_placeholder {
  align-items: flex-start;
  background-color: transparent;
  height: 12em;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  width: auto;
}

/* #player1 {
  @extend #video;
} */

.net_player {
  margin: 1em;
}

.video_aftermath {
  padding-top: 1em;
}

/* #endregion videotest */

/* #region audiooutputtest */
#audiosample {
  max-width: 90%;
  width: 30em;
}

.audio-meter {
  height: 3em;
}

/* #endregion audiooutputtest */

/* #region vu meter */

/* stylelint-disable */
#vumeter {
  margin-bottom: 2em;
  margin-left: 5em;
  margin-right: auto;
  margin-top: 2em;
  height: 150px;
  width: 300px;
}

.meter {
  background: #DFC68D;
  height: 150px;
  overflow: hidden;
  position: relative;
  width: 300px;
}

.needle {
  background: black;
  bottom: 10px;
  height: 2px;
  left: 30px;
  position: absolute;
  transform: rotate(20deg);
  transform-origin: 100% 0;
  transition: transform 0.05s;
  width: 120px;
  z-index: 9;
}

.shadow_meter {
  border-left: solid 30px rgba(0, 0, 0, 0.3);
  border-top: solid 40px rgba(0, 0, 0, 0.3);
  filter: blur(5px);
  height: 100%;
  left: -10px;
  position: absolute;
  top: -10px;
  width: 100%;
}

.label_meter {
  position: absolute;
  text-align: center;
  top: 55%;
  width: 100%;
}

.meter .indicator {
  font-size: 1.5em;
  left: 20px;
  position: absolute;
  top: 20px;
}

.meter .indicator.plus {
  color: #A00;
  left: auto;
  right: 20px;
}

.needleBase {
  bottom: 9px;
  height: 32px;
  left: 110px;
  overflow: hidden;
  position: absolute;
  width: 75px;
  z-index: 1;
}

.needleBase span {
  background: #333;
  border-radius: 100%;
  display: block;
  height: 100%;
  position: absolute;
  top: 50%;
  width: 100%;
}

.meterLine {
  height: 97px;
  left: 30px;
  overflow: hidden;
  position: absolute;
  top: 8px;
  width: 250px;
  z-index: 1;
}

.meterLine span.base_meter {
  border: solid 1px black;
  border-radius: 100%;
  height: 180px;
  left: 25px;
  position: absolute;
  top: 32px;
  width: 180px;
}

.meterLine .danger {
  height: 75px;
  left: 145px;
  overflow: hidden;
  position: absolute;
  top: 22px;
  width: 30%;
}

.meterLine .danger span.base_meter {
  border: solid 10px #A00;
  left: -120px;
  position: absolute;
  top: 0;
}

/* stylelint-enable */

/* #endregion vu meter */

/* #region progressbar */
#progressbar {
  //border-bottom: 0.1em solid lightgrey;
  color: lightgrey;
  display: flex;
  justify-content: flex-start;
  margin-left: 0;
  margin-top: 2em;
  overflow: hidden;
  padding-left: 0;
  padding-top: 0.5em;
}

#progressbar .active {
  border-bottom: 0.2em solid #EE7F01;
  color: #EE7F01;
}

#progressbar li {
  // border-bottom: 0.1em solid lightgrey;
  float: left;
  font-size: 100%;
  font-weight: 400;
  list-style-type: none;
  position: relative;
  width: 20%;
}

#progressbar li.active::before,
#progressbar li.active::after {
  background: #673AB7;
}

.progress-bar {
  background-color: #673AB7;
}

.kb_element {
  cursor: pointer;
}

/* #endregion progressbar */
.knowledge_side {
  min-width: 20%;
}

.monc-kb {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}

/* mobile device option */

/* stylelint-disable */
@media only screen and (max-width: 1529px) {
  /* stylelint-enable */
  .share_and_support {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: auto;
  }
}

/* stylelint-disable */
@media only screen and (max-width: 899px) {
  /* stylelint-enable */
  .monc_side_img {
    display: none;
  }

  .monc_side_img img {
    display: none;
    width: 5%;
  }

  .monc_start {
    flex-direction: column;
  }

  .monc_content {
    margin-left: 0;
  }

  .overview_side {
    max-width: none;
    width: 100%;
  }

  .monc-card-nocolor {
    margin-left: 0;
    margin-right: 1em;
  }

  .kb_index {
    align-items: center;
    /* stylelint-disable */
    border: 2px solid #EEE;
    /* stylelint-enable */
    display: flex;
    //flex-direction: row;
    flex-direction: column;
    justify-content: center;
    padding: 1em;
    width: 100%;
  }

  .kb_index div {
    display: none;
  }

  .kb_index li {
    padding: 1em;
  }
}

/* stylelint-disable */
@media only screen and (max-width: 749px) {
  /* stylelint-enable */
  #progressbar {
    display: none;
  }

  p,
  .monc_msg {
    margin-bottom: 1em;
    margin-top: 1em;
  }
}

/* stylelint-disable */
@media only screen and (max-width: 699px) {
  /* stylelint-enable */
  .monc_img_placeholder,
  .monc_img_placeholder img {
    display: none;
    height: 0;
  }

  .monc_btn_middel {
    margin-left: 0;
    margin-top: 1em;
  }

  .monc_input {
    width: 100%;
  }

  .support_btn {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
  }

  .support_btn label,
  .share_btn label {
    margin-right: 0;
  }

  .monc-card-nocolor {
    width: 90%;
  }

  .monc_msg,
  .monc_msg p {
    margin-right: 0;
  }

  .intro_next,
  .share_btn,
  .speaker_next,
  .micro_next,
  .video_next,
  .monc_next_dashboard {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .intro_next {
    margin-right: 0;
  }

  .radio_share_sm {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .monc_footer {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .monc_footer p {
    text-align: center;
  }

  .video_placeholder {
    align-items: center;
    justify-content: center;
  }

  #video {
    margin-left: 0;
    margin-right: 0;
    max-width: 98%;
  }

  .radio-item {
    margin-top: 0.5em;
  }

  .radio-item label {
    font-size: 100%;
    margin: 0;
  }
}

/* stylelint-disable */
@media only screen and (max-width: 550px) {
  /* stylelint-enable */
  .monc-card-nocolor,
  #email {
    width: 90%;
  }

  .monc_img_loading {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 2em auto auto;
    width: 30%;
  }

  .overview_side,
  .monc_content {
    //padding-left: 3rem;
  }

  .monc-kb {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }
}

/* stylelint-disable */
@media only screen and (max-width: 499px) {
  /* stylelint-enable */
  .sum_img {
    display: none;
    min-width: 0;
    width: 0;
  }

  .monc-card-nocolor {
    margin: 1em auto auto;
    min-width: 90%;
  }

  .monc_h {
    font-size: 150%;
  }

  .video_placeholder {
    align-items: center;
    display: flex;
    /* stylelint-disable */
    height: 250px;
    /* stylelint-enable */
    justify-content: center;
  }

  #video {
    margin-bottom: 1em;
    margin-top: 1em;
    /* stylelint-disable */
    max-height: 200px;
    /* stylelint-enable */
  }

  .monc_img_acc {
    max-width: 90%;
  }

  #vumeter {
    margin-left: 0.5em;
  }

  #jf_logo {
    display: none;
  }

  p,
  .monc_msg {
    margin-bottom: 1em;
    margin-top: 1em;
  }

  .monc_btn_middel {
    margin-bottom: 1em;
  }

  nav,
  main {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .monc_footer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  footer {
    font-size: 120%;
  }

  #audioOutput,
  #audioInput,
  #videoSource {
    margin-left: 0;
  }
}

/* stylelint-disable */
@media only screen and (max-width: 400px) {
  /* stylelint-enable */
  nav,
  main {
    font-size: 100%;
    margin-right: 1rem;
  }

  .monc_footer {
    padding-right: 1rem;
  }
}
